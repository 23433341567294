import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { collect } from 'collect.js';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import { useHistory } from 'react-router-dom';
import WebViewCommunicatorResource from '../../services/resources/WebViewCommunicatorResource';
import ReduxHooks from '../../store/ReduxHooks';
import { actionCreators } from '../../store/actions';
import { companyChannel } from '../../services/pusher/Channels';
import CloseOrderModal from '../../components/modals/close-order/CloseOrderModal';
import {
  DeliveryProvider,
  EVENT_HELPER_TYPES,
  FULFILLMENT_STATUSES,
  ORDERS_PAUSE_REASONS,
  ORDER_METHODS,
  PUSHER_EVENT_TYPES,
  RECEIPT_TYPE,
  ROUTES,
  WEBVIEW_COMMUNICATOR_COMMANDS,
} from '../../services/exports/Constants';
import NewOrdersFlicker from '../../components/flickers/NewOrdersFlicker';
import OrderFulfillmentsManager from '../../services/api/OrderFulfillmentsManager';
import OrderDetailsModal from '../../components/modals/order-details/OrderDetailsModal';
import ScheduledOrderReminderFlicker from '../../components/flickers/ScheduledOrderReminderFlicker';
import OrdersPausedFlicker from '../../components/flickers/OrdersPausedFlicker';
import ProfileManager from '../../services/api/ProfileManager';
import PendingOrdersFlicker from '../../components/flickers/PendingOrdersFlicker';
import usePusher from '../../hooks/usePusher';
import OrdersManager from '../../services/api/OrdersManager';
import useFeedbackHandler from '../../hooks/useFeedbackHandler';
import usePrinter from '../../hooks/usePrinter';
import OrderPickupTimeOptimizationPendingModal from '../../components/modals/delivery/OrderPickupTimeOptimizationPendingModal';
import useDeepRef from '../../hooks/useDeepRef';

function AlertsView() {
  const { t } = useTranslation(null, { keyPrefix: 'Views:Alerts:AlertsView' });
  const navigate = useHistory();
  const { client, unbind } = usePusher();
  const { print } = usePrinter();

  const { company } = useSelector((state) => state.company);
  const { eventHelper } = useSelector((state) => state);
  const { orders, newOrders, unreviewedOrders, scheduledOrders, ongoingOrders } = useSelector((state) => state.orders);
  const [selectedOrder, setSelectedOrder] = useState(null);

  // Modals
  const [showScheduledOrdersReminderFlicker, setShowScheduledOrdersReminderFlicker] = useState(false);
  const [showOrdersPausedFlicker, setShowOrdersPausedFlicker] = useState(ordersPaused());
  const [showPendingOrdersFlicker, setShowPendingOrdersFlicker] = useState(false);
  const [showCloseOrderModal, setShowCloserOrderModal] = useState(false);
  const [showOrderDetailsModal, setShowOrderDetailsModal] = useState(false);
  const [optimizingOrderPickupTime, setOptimizingOrderPickupTime] = useState(null);

  // Data Refs
  const selectedOrderRef = useRef(selectedOrder);
  selectedOrderRef.current = selectedOrder;

  const ordersRef = useRef(orders);
  ordersRef.current = collect(orders);

  const newOrdersRef = useRef(newOrders);
  newOrdersRef.current = collect(newOrders);

  const unreviewedOrdersRef = useRef(unreviewedOrders);
  unreviewedOrdersRef.current = collect(unreviewedOrders);

  const scheduledOrdersRef = useRef(scheduledOrders);
  scheduledOrdersRef.current = collect(scheduledOrders);

  const ongoingOrdersRef = useRef(ongoingOrders);
  ongoingOrdersRef.current = collect(ongoingOrders);

  const optimizingOrderPickupTimeRef = useDeepRef(optimizingOrderPickupTime);

  const [loading, setLoading] = useState(false);
  const { setFeedback } = useFeedbackHandler({
    renderToasts: true,
  });

  useEffect(() => {
    try {
      ReduxHooks.batchActions([
        actionCreators.orders.setNewOrders(),
        actionCreators.orders.setUnreviewedOrders(),
        actionCreators.orders.setScheduledOrders(),
        actionCreators.orders.setOngoingOrders(),
      ]);
    } catch (error) {
      console.error('Failed to handle batch actions', error);
    }
  }, [orders]);

  useEffect(() => {
    client && company?.id && initData();

    return () => cleanup();
  }, [client, company?.id]);

  async function connectPusher() {
    client
      .subscribe(companyChannel(company.id))
      .bind(PUSHER_EVENT_TYPES.ORDER_PAID, (data) => orderPaidHandler(data))
      .bind(PUSHER_EVENT_TYPES.ORDER_REFUNDED, (data) => orderRefundedHandler(data))
      .bind(PUSHER_EVENT_TYPES.ORDER_UPDATED, (data) => orderUpdatedHandler(data))
      .bind(PUSHER_EVENT_TYPES.PRINT_ORDER, (data) => printOrderHandler(data))
      .bind(PUSHER_EVENT_TYPES.ORDER_PICKUP_TIME_OPTIMIZED, (data) => orderPickupTimeOptimizedHandler(data))
      .bind(PUSHER_EVENT_TYPES.ORDER_COMPLETE, (data) => orderCompleteHandler(data))
      .bind(PUSHER_EVENT_TYPES.REFUND_CREATED, (data) => refundCreatedHandler(data))
      .bind(PUSHER_EVENT_TYPES.ORDERS_PAUSED, (data) => ordersPausedHandler(data))
      .bind(PUSHER_EVENT_TYPES.ORDERS_UNPAUSED, () => ordersUnpausedHandler());
  }

  function cleanup() {
    unbind(
      [
        PUSHER_EVENT_TYPES.ORDER_PAID,
        PUSHER_EVENT_TYPES.ORDER_REFUNDED,
        PUSHER_EVENT_TYPES.ORDER_UPDATED,
        PUSHER_EVENT_TYPES.PRINT_ORDER,
        PUSHER_EVENT_TYPES.ORDER_PICKUP_TIME_OPTIMIZED,
        PUSHER_EVENT_TYPES.ORDER_COMPLETE,
        PUSHER_EVENT_TYPES.REFUND_CREATED,
        PUSHER_EVENT_TYPES.ORDERS_PAUSED,
        PUSHER_EVENT_TYPES.ORDERS_UNPAUSED,
      ],
      companyChannel(company.id),
    );
  }

  useEffect(() => {
    const id = setInterval(() => {
      if (scheduledOrdersRef.current.filter((item) => isScheduledOrderReadyToStart(item)).isNotEmpty()) {
        setShowScheduledOrdersReminderFlicker(true);
      }
    }, 10000);

    return () => clearInterval(id);
  }, [scheduledOrdersRef.current]);

  useEffect(() => {
    handleUnreviewedOrder(selectedOrderRef.current);
  }, [selectedOrderRef.current]);

  useEffect(() => {
    handleReduxSideEffects();
  }, [eventHelper]);

  // Alerts
  useEffect(() => {
    const isAlertStartPointPassed = (order) =>
      order && moment().isSameOrAfter(moment(order.created_at).add(10, 'seconds'));

    const id = setInterval(() => {
      const alertStartPointPassed =
        isAlertStartPointPassed(unreviewedOrdersRef.current?.last()) ||
        isAlertStartPointPassed(newOrdersRef.current?.last());

      setShowPendingOrdersFlicker(alertStartPointPassed);

      if (alertStartPointPassed) {
        playAlert();
      }
    }, 10000);

    return () => clearInterval(id);
  }, [unreviewedOrdersRef.current, newOrdersRef.current]);

  useEffect(() => {
    const id = setInterval(() => {
      const shouldPlayAlert =
        showOrdersPausedFlicker && company?.orders_pause_reason === ORDERS_PAUSE_REASONS.inactivity;

      if (shouldPlayAlert) {
        playAlert();
      }
    }, 15000);

    return () => clearInterval(id);
  }, [showOrdersPausedFlicker, company]);

  async function initData() {
    if (!loading) {
      requestActiveOrders();
    }

    connectPusher();
  }

  async function requestActiveOrders() {
    setLoading(true);
    const { success, data } = await OrdersManager.get({
      paginated: false,
      sort: '-paid_at',
      filter: {
        pending: true,
        takeout: true,
      },
    });
    setLoading(false);

    if (!success) {
      return setFeedback({
        type: 'error',
        message: t('error_messages.failed_to_fetch_orders'),
      });
    }

    ReduxHooks.dispatch(actionCreators.orders.setOrders(data.data));
  }

  async function fetchOrderInfo(id, callback) {
    setLoading(true);
    const { success, data } = await OrdersManager.show(id);
    setLoading(false);

    if (success) {
      return callback(data?.data);
    }

    return setFeedback({
      type: 'error',
      message: t('error_messages.failed_to_fetch_orders'),
    });
  }

  const refreshSelectedOrder = () => {
    fetchOrderInfo(selectedOrderRef.current?.id, (orderData) => {
      ReduxHooks.dispatch(
        actionCreators.orders.setOrders(
          ordersRef.current.transform((item) => (item.id === orderData.id ? orderData : item)).toArray(),
        ),
      );
      setSelectedOrder(orderData);
    });
  };

  function playAlert(type = WEBVIEW_COMMUNICATOR_COMMANDS.PLAY_SOUND) {
    WebViewCommunicatorResource.playSound(type);
  }

  function orderPaidHandler(data) {
    playAlert();

    fetchOrderInfo(data?.id, (orderData) => {
      ReduxHooks.dispatch(actionCreators.orders.setOrders([orderData, ...ordersRef.current.toArray()]));

      if (company?.auto_accept_orders && orderData?.is_manually_confirmed) {
        print({ type: RECEIPT_TYPE.ORDER, payload: orderData });
      }
    });
  }

  function orderRefundedHandler(data) {
    if (selectedOrderRef.current?.id === data.id) {
      setSelectedOrder(null);
      setShowOrderDetailsModal(false);
    }

    if (!data.is_hidden_by_staff) {
      return;
    }

    ReduxHooks.dispatch(
      actionCreators.orders.setOrders(ordersRef.current?.reject((item) => item.id === data.id).toArray()),
    );
  }

  function orderUpdatedHandler(data, callback = null) {
    const _callback =
      callback ||
      ((orderData) =>
        ReduxHooks.dispatch(
          actionCreators.orders.setOrders(
            ordersRef.current.transform((item) => (item.id === orderData.id ? orderData : item)).toArray(),
          ),
        ));

    fetchOrderInfo(data?.id, _callback);
  }

  function printOrderHandler(data) {
    fetchOrderInfo(data?.id, (orderData) => print({ type: RECEIPT_TYPE.ORDER, payload: orderData }));
  }

  function orderPickupTimeOptimizedHandler(data) {
    playAlert();

    orderUpdatedHandler(data, (orderData) => {
      ReduxHooks.dispatch(
        actionCreators.orders.setOrders(
          ordersRef.current.transform((item) => (item.id === orderData.id ? orderData : item)).toArray(),
        ),
      );

      if (orderData.id === optimizingOrderPickupTimeRef.current?.id) {
        setOptimizingOrderPickupTime(orderData);
      }

      if (company?.auto_print && orderData.is_manually_confirmed) {
        print({ type: RECEIPT_TYPE.ORDER, payload: orderData });
      }
    });
  }

  function orderCompleteHandler(data) {
    ReduxHooks.dispatch(
      actionCreators.orders.setOrders(ordersRef.current.reject((item) => item.id === data.id).toArray()),
    );
  }

  function refundCreatedHandler(data) {
    if (data.order_id === selectedOrderRef.current?.id) {
      return refreshSelectedOrder();
    }

    return fetchOrderInfo(data?.order_id, (orderData) =>
      ReduxHooks.dispatch(
        actionCreators.orders.setOrders(
          ordersRef.current.transform((item) => (item.id === orderData.id ? orderData : item)).toArray(),
        ),
      ),
    );
  }

  function ordersPausedHandler(data) {
    ReduxHooks.dispatch(actionCreators.company.set(data));

    if (data?.orders_pause_reason !== ORDERS_PAUSE_REASONS.manual) {
      setShowOrdersPausedFlicker(true);
    }
  }

  function ordersUnpausedHandler() {
    ReduxHooks.dispatch(
      actionCreators.company.set({
        ...company,
        orders_paused_from: null,
        orders_paused_until: null,
        orders_pause_reason: null,
      }),
    );

    setShowOrdersPausedFlicker(false);
  }

  async function handleReduxSideEffects() {
    try {
      const { type, payload } = eventHelper.event;

      if (!type) {
        return;
      }

      new Promise((resolve) => {
        switch (type) {
          case EVENT_HELPER_TYPES.OPEN_ORDER_DETAILS_MODAL:
            toggleOrderDetailsModal(payload);
            break;
          case EVENT_HELPER_TYPES.OPEN_CLOSE_ORDER_MODAL:
            toggleCloserOrderModal(payload);
            break;
          default:
            break;
        }
        resolve(true);
      }).then(() => ReduxHooks.dispatch(actionCreators.eventHelper.resetEvent()));
    } catch (error) {
      console.error('Failed to handle redux side effects', error);
    }
  }

  async function markOrderHidden(order) {
    const { success } = await OrderFulfillmentsManager.update(order.fulfillment_id, {
      is_hidden_by_staff: true,
    });

    if (success) {
      return ReduxHooks.dispatch(
        actionCreators.orders.setOrders(ordersRef.current.reject((item) => item.id === order.id).toArray()),
      );
    }

    return setFeedback({
      message: t('error_messages.something_went_wrong'),
      type: 'error',
    });
  }

  async function markOrderComplete(order) {
    if (order.fulfillment_status === FULFILLMENT_STATUSES.rejected) {
      return markOrderHidden(order);
    }

    const { success } = await OrderFulfillmentsManager.update(order.fulfillment_id, {
      status:
        order.method === ORDER_METHODS.delivery && order.delivery_provider === DeliveryProvider.FirstDelivery
          ? FULFILLMENT_STATUSES.on_delivery
          : FULFILLMENT_STATUSES.picked,
      is_hidden_by_staff: true,
    });

    if (success) {
      return ReduxHooks.dispatch(
        actionCreators.orders.setOrders(ordersRef.current.reject((item) => item.id === order.id).toArray()),
      );
    }

    return setFeedback({
      message: t('error_messages.something_went_wrong'),
      type: 'error',
    });
  }

  function toggleCloserOrderModal(order) {
    setSelectedOrder(order);
    setShowCloserOrderModal(!showCloseOrderModal);
  }

  function toggleOrderDetailsModal(order) {
    setSelectedOrder(order);
    setShowOrderDetailsModal(!showOrderDetailsModal);
  }

  async function handleUnreviewedOrder(order) {
    if (order?.fulfillment_status !== FULFILLMENT_STATUSES.created) {
      return null;
    }

    const { success, data } = await OrderFulfillmentsManager.markReviewed(order.fulfillment_id);

    if (success) {
      selectedOrderRef.current?.id === data.data.id && setSelectedOrder(data.data);

      return ReduxHooks.dispatch(
        actionCreators.orders.setOrders(
          ordersRef.current.transform((item) => (item.id === order.id ? data.data : item)).toArray(),
        ),
      );
    }

    return setFeedback({
      message: t('error_messages.something_went_wrong'),
      type: 'error',
    });
  }

  async function handleAcceptedOrder(order) {
    !order.is_pickup_time_optimized && setOptimizingOrderPickupTime(order);

    return ReduxHooks.dispatch(
      actionCreators.orders.setOrders(
        ordersRef.current?.transform((item) => (item.id === order.id ? order : item)).toArray(),
      ),
    );
  }

  async function handleOrderPreparingStatus(order) {
    ReduxHooks.dispatch(
      actionCreators.orders.setOrders(
        ordersRef.current?.transform((item) => (item.id === order.id ? order : item)).toArray(),
      ),
    );

    toggleOrderDetailsModal();
  }

  async function handleUpdatedPickupTime(order) {
    ReduxHooks.dispatch(
      actionCreators.orders.setOrders(
        ordersRef.current.transform((item) => (item.id === order.id ? order : item)).toArray(),
      ),
    );

    toggleOrderDetailsModal();
  }

  function openNextUnreviewedOrder() {
    setSelectedOrder(unreviewedOrdersRef.current?.first());
    setShowOrderDetailsModal(true);
  }

  function openNextPendingOrder() {
    setSelectedOrder(newOrdersRef.current?.last());
    setShowOrderDetailsModal(true);
    setShowPendingOrdersFlicker(false);
  }

  function closeStartScheduledOrdersFlicker() {
    setShowScheduledOrdersReminderFlicker(false);
    setSelectedOrder(scheduledOrdersRef.current.first((item) => isScheduledOrderReadyToStart(item)));
    setShowOrderDetailsModal(true);
  }

  async function closeOrdersPausedFlicker() {
    const { success, data } = await ProfileManager.unpauseOrders();

    if (success) {
      setShowOrdersPausedFlicker(false);
      ReduxHooks.dispatch(actionCreators.company.set(data.data));
      return navigate.push(ROUTES.home);
    }

    return setFeedback({
      message: t('error_messages.something_went_wrong'),
      type: 'error',
    });
  }

  function getFlickerType() {
    return unreviewedOrdersRef.current?.first()?.scheduled_for === null ? 'default' : 'scheduled';
  }

  function isScheduledOrderReadyToStart(order) {
    const minutes =
      order.method === ORDER_METHODS.delivery
        ? company.average_order_delivery_time + company.busy_mode_minutes
        : company.average_order_preparation_time + company.busy_mode_minutes;

    return moment().isSameOrAfter(moment(order.estimated_pickup_time).subtract(minutes, 'minutes'));
  }

  function ordersPaused() {
    return (
      company?.orders_paused_from !== null &&
      moment(company.orders_paused_from).isBefore(moment()) &&
      company?.orders_paused_until !== null &&
      moment(company.orders_paused_until).isAfter(moment()) &&
      company?.orders_pause_reason !== ORDERS_PAUSE_REASONS.manual
    );
  }

  const renderCloseOrderModal = useMemo(
    () =>
      showCloseOrderModal &&
      selectedOrderRef.current && (
        <CloseOrderModal
          loading={loading}
          showOrderDetailsModal={showOrderDetailsModal}
          toggleModal={() => toggleCloserOrderModal(null)}
          toggleOrderDetailsModal={toggleOrderDetailsModal}
          order={selectedOrderRef.current}
          onApprove={markOrderComplete}
        />
      ),
    [showCloseOrderModal, showOrderDetailsModal, selectedOrderRef.current],
  );

  const renderOrderDetailsModal = useMemo(
    () =>
      showOrderDetailsModal &&
      selectedOrderRef.current && (
        <OrderDetailsModal
          loading={loading}
          show={showOrderDetailsModal}
          toggleModal={toggleOrderDetailsModal}
          toggleCloseOrderModal={toggleCloserOrderModal}
          order={selectedOrderRef.current}
          onOrderClose={markOrderComplete}
          onOrderAccepted={handleAcceptedOrder}
          onPickupTimeUpdated={handleUpdatedPickupTime}
          onOrderPreparing={handleOrderPreparingStatus}
          onOrderRefunded={orderRefundedHandler}
          refreshInfo={refreshSelectedOrder}
        />
      ),
    [loading, showOrderDetailsModal, selectedOrderRef.current],
  );

  const renderNewOrdersFlicker = useMemo(
    () =>
      unreviewedOrdersRef.current?.isNotEmpty() && (
        <NewOrdersFlicker
          ordersCount={unreviewedOrdersRef.current?.count()}
          onClick={openNextUnreviewedOrder}
          type={getFlickerType()}
        />
      ),
    [unreviewedOrdersRef.current],
  );

  const renderPendingOrdersFlicker = useMemo(
    () =>
      newOrdersRef.current?.isNotEmpty() &&
      showPendingOrdersFlicker && (
        <PendingOrdersFlicker
          orders={newOrdersRef.current}
          onClick={openNextPendingOrder}
          onTimerEnd={orderRefundedHandler}
        />
      ),
    [showPendingOrdersFlicker, newOrdersRef.current],
  );

  const renderScheduledOrderReminderFlicker = useMemo(
    () =>
      company?.has_scheduled_order_reminders &&
      showScheduledOrdersReminderFlicker &&
      scheduledOrdersRef.current.filter((item) => isScheduledOrderReadyToStart(item)).isNotEmpty() && (
        <ScheduledOrderReminderFlicker
          orders={scheduledOrdersRef.current.filter((item) => isScheduledOrderReadyToStart(item))}
          onClick={closeStartScheduledOrdersFlicker}
        />
      ),
    [company?.has_scheduled_order_reminders, showScheduledOrdersReminderFlicker, scheduledOrdersRef.current],
  );

  const renderOrdersPausedFlicker = useMemo(
    () => (showOrdersPausedFlicker || ordersPaused()) && <OrdersPausedFlicker onClick={closeOrdersPausedFlicker} />,
    [showOrdersPausedFlicker, company.orders_paused_from, company.orders_paused_until],
  );

  return (
    <>
      {renderCloseOrderModal}
      {renderOrderDetailsModal}
      {renderNewOrdersFlicker}
      {renderPendingOrdersFlicker}
      {renderScheduledOrderReminderFlicker}
      {renderOrdersPausedFlicker}
      {optimizingOrderPickupTime && (
        <OrderPickupTimeOptimizationPendingModal
          order={optimizingOrderPickupTime}
          toggleModal={() => setOptimizingOrderPickupTime(null)}
        />
      )}
    </>
  );
}

export default AlertsView;
