import BackendApiClient from './BackendApiClient';

class RefundsManager {
  create = async (paymentId, data) =>
    BackendApiClient.requestAuthorized({
      method: 'POST',
      url: `/staff/payments/${paymentId}/refunds`,
      data,
    });
}

export default new RefundsManager();
